<template lang="pug">
    .w-full
        .flex.items-center.justify-between.mb-4
            .heading-submenu
                h2.subheading.flex-grow Lokasi Terakhir
            .flex.items-center.gap-2
                
                .block.w-54(v-if="detail.msisdn && detail.msisdn.length > 0")
                    .field.field--icon-right.w-full
                        select.field-style(v-model="selected_msisdn" @change="changeMsisdn()")
                            option(v-for="(item,index) in detail.msisdn" :key="index") {{ item }}
                        span.icon-field
                            b-icon(name="chevron-down")

                a.block(v-if="location.result && location.result.latitude && location.result.longitude && location.result.latitude.length > 0 && location.result.longitude.length > 0" :href="'https://maps.google.com/?q='+ location.result.latitude +','+ location.result.longitude" target="blank")
                    b-button(text='Kunjungi lokasi' kind="primary" text_icon_left="send")
        
        .card-bg-theme.p-6.rounded.shadow.w-full(v-if="status_detail === 'loading' || status_location.status === 'loading'")
            //- .m-20.p-20.relative.text-center.w-full.h-full
            .p-20.relative.text-center.w-full.h-full
                b-loading(text="Memuat data")

        .card-bg-theme.p-6.rounded.shadow.w-full(v-else)
            .grid.grid-cols-3.gap-4
                .callout
                    span mnc
                    p {{ location.result && location.result.mnc && location.result.mnc.length > 0 ? location.result.mnc : '-' }}

                .callout
                    span address
                    p {{ location.result && location.result.address && location.result.address.length > 0 ? location.result.address : '-' }}

                .callout
                    span code
                    p {{ location.result && location.result.code && location.result.code.length > 0 ? location.result.code : '-' }}

                .callout
                    span cgi
                    p {{ location.result && location.result.cgi && location.result.cgi.length > 0 ? location.result.cgi : '-' }}

                .callout
                    span ci
                    p {{ location.result && location.result.ci && location.result.ci.length > 0 ? location.result.ci : '-' }}

                .callout
                    span latitude
                    p {{ location.result && location.result.latitude && location.result.latitude.length > 0 ? location.result.latitude : '-' }}

                .callout
                    span longitude
                    p {{ location.result && location.result.longitude && location.result.longitude.length > 0 ? location.result.longitude : '-' }}

                .callout
                    span imsi
                    p {{ location.result && location.result.imsi && location.result.imsi.length > 0 ? location.result.imsi : '-' }}

                .callout
                    span mcc
                    p {{ location.result && location.result.mcc && location.result.mcc.length > 0 ? location.result.mcc : '-' }}

                .callout
                    span message
                    p {{ location.result && location.result.message && location.result.message.length > 0 ? location.result.message : '-' }}

                .callout
                    span lac
                    p {{ location.result && location.result.lac && location.result.lac.length > 0 ? location.result.lac : '-' }}

                .callout
                    span qtime
                    p {{ location.result && location.result.qtime && location.result.qtime.length > 0 ? location.result.qtime : '-' }}

                .callout
                    span phone
                    p {{ location.result && location.result.phone && location.result.phone.length > 0 ? location.result.phone : '-' }}

                .callout
                    span enb
                    p {{ location.result && location.result.enb && location.result.enb.length > 0 ? location.result.enb : '-' }}

                .callout
                    span prefix_type
                    p {{ location.result && location.result.prefix_type && location.result.prefix_type.length > 0 ? location.result.prefix_type : '-' }}

                .callout
                    span imei
                    p {{ location.result && location.result.imei && location.result.imei.length > 0 ? location.result.imei : '-' }}

                .callout
                    span state
                    p {{ location.result && location.result.state && location.result.state.length > 0 ? location.result.state : '-' }}

                .callout
                    span state code
                    p {{ location.result && location.result.state_code && location.result.state_code.length > 0 ? location.result.state_code : '-' }}

                .callout
                    span aol
                    p {{ location.result && location.result.aol && location.result.aol.length > 0 ? location.result.aol : '-' }}

</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'MsisdnLocation',
    data() {
        return {
            selected_msisdn: '',
        }
    },
    computed: {
        ...mapState('actor', [
            'status_detail',
            'detail',
            'status_location',
            'location',
        ]),
    },
    watch: {
        status_detail(newVal) {
            if (newVal.status === 'loading')
                this.getData(true);
        },
        detail(newVal) {
            //- console.log(newVal);
            
            //- temporary -> dummy data
            //- newVal.msisdn = '6288215243013';
            
            if (newVal && newVal.msisdn && newVal.msisdn.length > 0) {
                this.selected_msisdn = newVal.msisdn[0];
                this.getData(false);
            }
        },
    },
    methods: {
        changeMsisdn() {
            this.getData(false);
        },
        async getData(loading=false) {
            if (loading === true) {
                let status = this.status_location;

                if (status.status === 'loading' && status.cancel === null) {
                    status.status = false;

                } else {
                    status.status = 'loading';
                }

                this.$store.commit('actor/setStatusLocation', status);
            
            } else {
                //- this.$store.dispatch('actor/getLocation', this.detail.msisdn);
                this.$store.dispatch('actor/getLocation', this.selected_msisdn);
            }
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            //- console.log(to.name)
            if (to.name === 'MsisdnOfActorLocation') {
                vm.$store.commit('actor/setLocation', {});
                if (vm.detail && vm.detail.msisdn && vm.detail.msisdn.length > 0) {
                    vm.selected_msisdn = vm.detail.msisdn[0];
                    vm.getData(false);
                }
            }
        });
    },
}
</script>

<style lang="sass">

</style>